import type { CompanyFormI, MemberI, ProfileI } from '@/types/account'

export function useAccountApi() {
  const { get, put, post, deletion } = useApi()
  const accountEndpoint = 'account'
  const membersEndpoint = 'members'
  const customerEndpoint = 'customer'

  function fetchAccountData() {
    return get(accountEndpoint)
  }

  function submitPreference(data: { timezone: string | undefined }) {
    return put(`${accountEndpoint}/preferences`, data)
  }

  function submitProfile(data: ProfileI) {
    return put(accountEndpoint, data)
  }

  function updateProfilePic(data: GenericObjectI) {
    return post(`${accountEndpoint}/picture`, data)
  }

  function deleteProfilePic() {
    return deletion(`${accountEndpoint}/picture`)
  }

  function changePassword(data: { old_password: string, new_password: string }) {
    return put(`${accountEndpoint}/password`, data)
  }

  function updatePhone(data: { mobile_phone: string }) {
    return put(accountEndpoint, data)
  }

  function toggle2FA() {
    return put(`${accountEndpoint}/2fa`)
  }

  function fetchMembers() {
    return get(membersEndpoint)
  }

  function createMember(data: MemberI) {
    return post(membersEndpoint, data)
  }

  function updateMember({ data, id }: { data: MemberI, id: string }) {
    return put(`${membersEndpoint}/${id}`, data)
  }

  function deleteMember(id: string) {
    return deletion(`${membersEndpoint}/${id}`)
  }

  function updateMemberStatus({ memberId, data }: { memberId: string, data: { active: boolean } }) {
    return put(`${membersEndpoint}/${memberId}/status`, data)
  }

  function updateScope(memberId: string, scope: string, allow: boolean = true) {
    return put(`${membersEndpoint}/${memberId}/scope`, { scope, allow })
  }

  function switchCustomer(data: { customer_id: string }) {
    return post(`${customerEndpoint}/switch`, data)
  }

  function assignRoles(data: { roles: string[], id: string }) {
    return put('roles/assign-to-user', data)
  }

  function submitCustomerData(data: CompanyFormI) {
    return put(customerEndpoint, data)
  }

  return {
    // profile
    fetchAccountData,
    submitPreference,
    submitProfile,
    updateProfilePic,
    deleteProfilePic,
    changePassword,
    updatePhone,
    toggle2FA,
    // members
    fetchMembers,
    createMember,
    updateMember,
    deleteMember,
    updateMemberStatus,
    updateScope,
    switchCustomer,
    // role
    assignRoles,
    // customer
    submitCustomerData,
  }
}
